<template >
  <div class="about">
    <div class="header">
      <Parallax
        :speed="-.25"
        parallaxType="Offset"
        style="position:relative; top:0; right:0; display:block; height:100%; display:block;"
        :marged="false"
        :masked="true"
        :skewed="false"
        :autosize="true"
        :rotate="true"
        :degree="0"
        :minMax="true"
      >
        <v-img :src="headerAteliers" :contain="false" :height="((isMobile)?20:40)+'vh'" />
      </Parallax>
    </div>
    <v-container style="padding-bottom:140px;">
      <div class="article_content">
        <h1 class="styloText">Expérience Utilisateur</h1>
        <br />
        <p class="styloText first_paragraph">
          <b>
            <span
              class="surligneur"
            >Du Sprint au prototype en passant par des ateliers de Design Thinking.</span>
          </b>
          <br />Les différentes étapes d'idéation lors de la conception et écriture de produits numériques
          sont incontournables pour établir une expérience en adéquation avec les objectifs.
          <b>
            <span class="surligneur">J'apporte un regard neuf, éguisé et externe aux projets</span>
          </b> de façon à atteindre une lecture commune et harmonieuse en amont à la création et au développement.
        </p>
        <br />
        <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Ateliers</h2>
        </Onscreen>
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <div class="block_image" style="height:128px;">
                  <lottie
                    :options="sprint"
                    v-on:animCreated="handleAnimation"
                    width="128"
                    height="128"
                    type="sized"
                    :autoplay="true"
                    :loop="true"
                    style="margin:0;"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Sprint Design</h2>
                <p class="styloText">
                  L'objectif d'un sprint et de la co-conception Agile est d'établir les facteurs nécéssaires à l'èlaboration de vos futur produits ou
                  d'itérer sur un produit en amélioration continu.
                  <a
                    href="https://bige-start.web.app/feeds/un-sprint-design-en-5-etapes"
                    title="Sprint Design"
                    target="_blank"
                  >Voici un postit pour en savoir plus sur les ateliers Sprint Design.</a>
                  <b>
                    <span class="surligneur">Un regard externe est la clé du succés d'un Sprint.</span>
                  </b>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <lottie
                    :options="thinking"
                    v-on:animCreated="handleAnimation"
                    width="128"
                    height="128"
                    type="sized"
                    :autoplay="true"
                    :loop="true"
                    style="margin:0; height:128px;"
                    ratio="xMidYMid meet"
                  />
                </div>
                <h2 class="styloText">Design Thinking</h2>
                <p class="styloText">
                  Nous participons à vos ateliers de Design Thinking en y apportant un réel savoir faire en écriture et conception de prototypes.
                  <br />Pour répondre à vos questions sur ce qu'est un
                  <a
                    href="/feeds/quelques-tips-en-design-thinking"
                    title="design thinking"
                    target="_blank"
                  >Atelier de Design thinking nous avons écris ce petit Postit.</a>
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="ideation" height="128" width="128" />
                </div>
                <h2 class="styloText">Workshop d'idéations</h2>
                <p class="styloText">
                  Lors de phases d'idéation sur un produit il est parfois nécéssaire d'obtenir des avis et expertises externes,
                  c'est dans ce cadre que Bige propose sa présence de façon à
                  <b>
                    <span class="surligneur">disrupter des concepts</span>
                  </b> lorsqu'il devient complexe de prendre du recul.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="prototypage" height="128" width="128" />
                </div>
                <h2 class="styloText">Prototypage</h2>
                <p class="styloText">
                  Nous apportons un réel savoir faire en prototypage depuis le papier jusqu'à la mise en oeuvre de prototypes dynamiques destinés à éprouver
                  les parcours utilisateurs de A à Z. Nous travaillons avec des outils contemporains adaptés à la complexité de tout type de produits.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="growth" height="128" width="128" />
                </div>
                <h2 class="styloText">Growth Hacking</h2>
                <p class="styloText">
                  Avant le lancement d'un nouveau produit, il est judicieux d'élaborer des stratégies de diffusion intelligeante.
                  C'est avec une connaissance approfondies des différentes plateformes, réseaux sociaux et autres vannaux de communication que Bige propose
                  la co-écriture de stratégie du diffusion destinées à disrupter les coûts relatifs aux achats de supports.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="strategy" height="128" width="128" />
                </div>
                <h2 class="styloText">Rédaction et Brand Content</h2>
                <p class="styloText">
                  En collaboration avec divers réseaux de rédacteurs et professionnels du SEO,
                  nous apportons des solutions et conseillons sur l'élaboration de contenu de marque et services destinés à créer
                  de l'apétence envers vos utilisateurs. La rédaction et le SEO ne faisant pas parti prenante de Bige,
                  nous suggérons, orientons et nous mettons en relation avec des experts du domaine.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="advice" height="128" width="128" />
                </div>
                <h2 class="styloText">Conseil en orientation</h2>
                <p class="styloText">
                  Avec plus de 15 années de pratique et d'expériences variées en entreprise comme en développement de Start-Up,
                  nous partageons nos visions et élaborons ensemble au travers de conseils en conception et stratégie.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121 )" :delay="500">
              <div>
                <div class="block_image" style="height:128px;">
                  <v-img :src="scrum" height="128" width="128" />
                </div>
                <h2 class="styloText">Méthodologies SCRUM</h2>
                <p class="styloText">
                  Que ce soit pour une première mise en place ou une relecture des pratiques et outils déjà déployés.
                  Nous conseillons et partageons nos expériences destinées à améliorer vos expériences de travail en équipe selon la typologie de profils
                  et autres consituantes liées aux métiers destinés à collaborer. Nous essayons toujours de simplifier et d'optimiser les process de façon
                  à apporter confort et gain de temps aux équipes constituantes de votre domaine d'activié. Nous aimons
                  <b>
                    partager avec votre SCRUM master
                    de façon à conforter ses choix
                  </b> et autres orientation sans pour autant nous positionner entant que tel.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </div>
</template>
<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";
import * as thinkingMotion from "@/assets/motions/UI/design_thinking.json";
import * as sprintMotion from "@/assets/motions/UI/sprint.json";
import strategy from "@/assets/images/SVG/strategy.svg";
import advice from "@/assets/images/SVG/advice.svg";
import scrum from "@/assets/images/SVG/scrum.svg";
import ideation from "@/assets/images/SVG/ideation.svg";
import growth from "@/assets/images/SVG/growth.svg";
import prototypage from "@/assets/images/SVG/prototypage.svg";
const Lottie = () => import("@/components/LottieMotion.vue");

import headerAteliers from "@/assets/images/HP/header_ateliers.jpg";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen, Lottie },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      }
    }
  },
  data() {
    return {
      headerAteliers: headerAteliers,
      thinking: {
        animationData: thinkingMotion.default,
        loop: true,
        autoplay: true
      },
      sprint: {
        animationData: sprintMotion.default,
        loop: true,
        autoplay: true
      },
      strategy: strategy,
      scrum: scrum,
      ideation: ideation,
      advice: advice,
      prototypage: prototypage,
      growth: growth
    };
  },
  methods: {
    handleAnimation: function() {}
  }
};
</script>
<style scoped>
.block_image {
  text-align: left;
}
</style>